import React, { useCallback, useEffect, useState } from "react";
import Accordion from "../Accordion/Accordion.jsx";
import JumpNavigation from "../JumpNavigation/JumpNavigation.jsx";
import getPageAnchors from "../../common/getPageAnchors.js";
import initAccordion from "../../common/initAccordions.js";
import useBoundingClientRect from "../../common/useBoundingClientRect.js";
import { shape, string } from "prop-types";

const propTypes = {
  dictionary: shape({
    inThisArticle: string.isRequired,
    sections: string.isRequired,
    backToTop: string.isRequired,
  }).isRequired,
};

const AnchorLinkNav = props => {
  const { dictionary } = props;

  const [accordion, setAccordion] = useState({});
  const [anchors, setAnchors] = useState([]);
  const [isAnchorNavOffscreen, setIsAnchorNavOffscreen] = useState(false);
  const [isWide, setIsWide] = useState(true);
  const [isLargeWide, setIsLargeWide] = useState(true);
  const [isRteOffscreen, setIsRteOffscreen] = useState(false);
  const [jumpOffset, setJumpOffset] = useState(0);
  const [pageSummaryRteRect, setPageSummaryRteRect] = useState({});
  const [stickyRect, stickyRef] = useBoundingClientRect();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollingUp, setScrollingUp] = useState(false);

  // Pixels to offset the anchor link nav from top of viewport
  const [anchorNavOffset] = useState(20);

  // When scrolling, reposition the anchor nav based on the current page layout
  const handleScroll = useCallback((e) => {
    if (scrollingUp !== scrollPosition > window.scrollY) {
      setScrollingUp(scrollPosition > window.scrollY);
    }

    if (scrollingUp) {
      document.body.classList.remove("is-scrolling-down");
    } else {
      document.body.classList.add("is-scrolling-down");
    }

    setScrollPosition(window.scrollY);

    // Get the dimensions of the page summary; it's the location of this element
    // that will determine whether to fix the position of the anchor link nav
    const pageSummaryRte = document.querySelector(".ssa__article-container__rte");
    const pageSummaryRteRect = pageSummaryRte.getBoundingClientRect();

    const leftAuthors = document.querySelector(".ssa__article-container__left .ssa__authors");
    // "65" is the margin between authors and anchor nav
    // anchor-link-nav.scss
    const leftAuthorsHeight =
      leftAuthors && leftAuthors.getBoundingClientRect().height > 0 ? leftAuthors.getBoundingClientRect().height + 65 : 0;

    const summaryHeight = Math.max(leftAuthorsHeight, 0);

    // Ok, *then* get the rich text content after the "article" area, where
    // all the subsequent content goes, because we don't want to fix the
    // anchor link nav if that starts scrolling off the top of the screen
    const richTextRect = document.querySelector(".ssa__article-container ~ .ssa__container").getBoundingClientRect();

    // Need to offset the fixed position by the height of the Sitecore ribbon
    // if it exists
    const scRibbon = document.querySelector("#scWebEditRibbon");
    const scRibbonHeight = scRibbon ? scRibbon.offsetHeight : anchorNavOffset;

    // Conditions for anchor nav being "offscreen"; different for whether we're
    // on a wide viewport or not
    const newAnchorNavOffscreen =
      isWide ?
        pageSummaryRteRect.y + summaryHeight <= scRibbonHeight :
        pageSummaryRteRect.y + pageSummaryRteRect.height - stickyRect.height <= anchorNavOffset;

    // Set whether the bottom of the authored rich text is above the sticky nav;
    // if it is, we want to fade out the sticky nav. Why not just unstick it?
    // Becase on mobile this causes the content to shift and results in
    // recursive content jumping.
    if (isRteOffscreen !== richTextRect.bottom < stickyRect.height) {
      setIsRteOffscreen(richTextRect.bottom < stickyRect.height);
    }

    if (newAnchorNavOffscreen != isAnchorNavOffscreen) {
      setIsAnchorNavOffscreen(newAnchorNavOffscreen);
    }
  }, [anchorNavOffset, scrollingUp, scrollPosition, isWide, stickyRect.height, isRteOffscreen, isAnchorNavOffscreen]);

  const handleResize = useCallback(() => {
    const newIsWide = window.innerWidth >= 680;
    if (newIsWide !== isWide) {
      setIsWide(newIsWide);
    }

    const newIsLargeWide = window.innerWidth >= 910;
    if (newIsLargeWide !== isLargeWide) {
      setIsLargeWide(newIsLargeWide);
    }

    // Get the dimensions of the page summary; it's the location of this element
    // that will determine whether to fix the position of the anchor link nav
    const newPageSummaryRteRect = document.querySelector(".ssa__article-container__rte").getBoundingClientRect();
    if (newPageSummaryRteRect.height != pageSummaryRteRect.height) {
      setPageSummaryRteRect(newPageSummaryRteRect);
    }
  }, [isWide, isLargeWide, pageSummaryRteRect]);

  useEffect(() => {
    const articleEl = document.querySelector(".ssa__article-container");

    // If the viewport is wide, then set the whole article container to be no
    // taller than the RTE part of it
    articleEl.style.setProperty("--height", isLargeWide ? `${pageSummaryRteRect.height}px` : "auto");
  }, [isLargeWide, pageSummaryRteRect.height]);

  useEffect(() => {
    const accordionTitle = document.querySelector(".ssa__accordion__title");
    const accordionTitleRect = accordionTitle.getBoundingClientRect();

    setJumpOffset(accordionTitleRect.height + anchorNavOffset * 2);

    if (isAnchorNavOffscreen) {
    // Get the dimensions of the anchor link nav's parent left container; use
    // this to set the anchor link nav's width (since it's going to have a
    // fixed position)
      const leftRect = stickyRef.current.closest(".ssa__article-container__left").getBoundingClientRect();

      // Need to offset the fixed position by the height of the Sitecore ribbon
      // if it exists
      const scRibbon = document.querySelector("#scWebEditRibbon");
      const stickyTop = scRibbon ? scRibbon.offsetHeight + anchorNavOffset : anchorNavOffset;

      stickyRef.current.classList.add("is-stuck");

      if (isRteOffscreen) {
        stickyRef.current.style.opacity = "0";
        stickyRef.current.style.visibility = "hidden";
      } else {
        stickyRef.current.style.opacity = "";
        stickyRef.current.style.visibility = "";
      }

      stickyRef.current.style.top = `${stickyTop}px`;

      if (isWide) {
        if (isLargeWide) {
          stickyRef.current.style.width = `${leftRect.width}px`;
        } else {
          stickyRef.current.style.width = "";
        }
        if (accordion.toggleContent) {
          accordion.toggleContent(false);
        }
      }
    } else {
      if (isLargeWide) {
        if (accordion.toggleContent) {
          accordion.toggleContent(true);
        }
      }

      stickyRef.current.style.opacity = "";
      stickyRef.current.style.visibility = "";
      stickyRef.current.style.width = "";
      stickyRef.current.classList.remove("is-stuck");
    }
  }, [anchorNavOffset, accordion, isAnchorNavOffscreen, isRteOffscreen, isWide, stickyRef]);

  useEffect(() => {
    handleResize();

    setAnchors(getPageAnchors("[data-include-in-nav=true]"));

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleResize, handleScroll]);

  useEffect(() => {
    if (accordion.el) {
      accordion.el.dataset.triggerModal = !isWide;
      accordion.el.dataset.closeOnClick = !isWide;
    }

    if (accordion.toggleContent) {
      accordion.toggleContent(isWide);
    }
  }, [isWide, accordion]);

  useEffect(() => {
    document.querySelectorAll(".js-ssa__accordion").forEach((el, i) => {
      setAccordion(initAccordion(el, i));
    });
  }, []);

  return (
    anchors && (
      <Accordion
        ref={stickyRef}
        classNames="ssa__accordion--jump-nav"
        closeOnClick={!isWide}
        triggerModal={!isWide}
        isOpen={isWide}
        title={(
            <>
              <svg className="ssa__accordion__title-icon" role="img" aria-hidden="true" height="17" viewBox="0 0 27 17" width="27" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor" fillRule="evenodd"><path d="m7 0h20v3h-20z"/><path d="m7 7h20v3h-20z"/><path d="m7 14h20v3h-20z"/><rect height="3" rx="1.5" width="3"/><rect height="3" rx="1.5" width="3" y="7"/><rect height="3" rx="1.5" width="3" y="14"/></g></svg>
              <span className="ssa__accordion__title-text">{dictionary.sections}</span>
              <svg className="ssa__accordion__arrow" role="presentation" height="5" viewBox="0 0 10 5" width="10" xmlns="http://www.w3.org/2000/svg"><path d="m244 18h10l-5 5z" fill="currentColor" fillRule="evenodd" transform="matrix(-1 0 0 -1 254 23)"/></svg>
            </>
        )}
        content={(
            <>
              <p className="ssa__jump-navigation__title">{dictionary.inThisArticle}</p>
              <JumpNavigation
                jumpOffset={!isWide ? stickyRect.height : jumpOffset}
                links={anchors}
                dictionary={dictionary}
              />
            </>
        )}
      />
    )
  );
};

AnchorLinkNav.propTypes = propTypes;
export default AnchorLinkNav;
