export default function getPageAnchors(selector) {
  const anchors = Array.from(document.querySelectorAll(selector)) || [];

  if (anchors.length <= 0) {
    return [];
  }

  let index = 0;

  const links = anchors.reduce((data, anchor) => {
    if (!anchor.textContent) {
      return data;
    }

    const target = anchor.id ? anchor.id : `nav-${index}`;
    anchor.setAttribute("data-index", index);
    anchor.setAttribute("id", target);
    anchor.setAttribute("tabindex", "-1");

    const label = (anchor.getAttribute("data-nav-title") === "none" && anchor.textContent) || anchor.getAttribute("data-nav-title") || anchor.textContent;

    data.push({
      label: label,
      index,
      href: `#${target}`,
    });

    index++;

    return data;
  }, []);

  return links;
}
