import React from "react";
import cx from "classnames";
import { bool, node, oneOfType, string } from "prop-types";

const propTypes = {
  title: oneOfType([node, string]).isRequired,
  content: node,
  isOpen: bool,
  classNames: string,
  closeOnClick: string,
  triggerModal: bool,
};

const Accordion = React.forwardRef((props, ref) => {
  const {
    title,
    content,
    isOpen,
    classNames,
    closeOnClick,
    triggerModal,
  } = props;

  return (
    <div
      ref={ref}
      className={cx("ssa__accordion js-ssa__accordion", classNames, {
        "is-open": isOpen,
      })}
      data-close-on-click={closeOnClick}
      data-trigger-modal={triggerModal}
    >
      <button
        className="ssa__accordion__title js-ssa__accordion-link"
        type="button"
        aria-expanded={isOpen ? "true" : "false"}
      >
        {title}
      </button>
      <div className="ssa__accordion__content js-ssa__accordion-content">{content}</div>
    </div>
  );
});

Accordion.propTypes = propTypes;

export default Accordion;
