import throttle from "lodash.throttle";

export const relatedInsight = () => {
  //make whole component clickable
  //fade into view on initial scroll

  const scrollTracker = () => {
    [...document.querySelectorAll(".js-related-insight")].forEach(el => {
      const top = el.getBoundingClientRect().top;
      const bottom = el.getBoundingClientRect().bottom;

      if (
        top >= 0 &&
        bottom <= window.innerHeight &&
        !el.classList.contains("ssa__related-insight--in-view")
      ) {
        el.classList.add("ssa__related-insight--in-view");
      }
    });
  };
  const isEE = document.querySelectorAll(".is-experience-editor").length > 0;

  if (!isEE) {
    window.addEventListener("scroll", throttle(scrollTracker, 100));
    [...document.querySelectorAll(".js-related-insight")].forEach(el => {
      const link = el.querySelector(".js-related-insight-link");

      link &&
        el.addEventListener("click", e => {
          e.preventDefault();
          var linkTarget = el
            .querySelector(".js-related-insight-link")
            .getAttribute("target");
          if (linkTarget != undefined) {
            window.open(el
              .querySelector(".js-related-insight-link")
              .getAttribute("href"), linkTarget);
          }
          else {
            window.location = el
              .querySelector(".js-related-insight-link")
              .getAttribute("href");
          }
          
        });
    });
  }
};
