import throttle from "lodash.throttle";

export const stickyToolbar = () => {
  [...document.querySelectorAll(".js-sticky-toolbar-container")].forEach(el => {
    const footer = document.querySelector(".site-footer");
    const stickyClass = "ssa__sticky-toolbar--unstick";

    if (footer) {
      const footerScrollTracker = () => {
        const top = footer.getBoundingClientRect().top;
        const bottom = footer.getBoundingClientRect().bottom;
        if (top >= 0 && bottom <= window.innerHeight) {
          el.classList.add(stickyClass);
        } else {
          el.classList.remove(stickyClass);
        }
      };
      window.addEventListener("scroll", throttle(footerScrollTracker, 100));
    }
  });

  [...document.querySelectorAll(".js-sticky-toolbar")].forEach(el => {
    const buttons = el.querySelectorAll("[data-tab]");
    const panelContainer = el.querySelector(".js-sticky-toolbar-tabpanel");
    const openClass = "is-current";

    const closeAll = () => {
      el.querySelectorAll(`[data-content]`).forEach(panel =>
        panel.classList.remove(openClass)
      );
      buttons.forEach(button => {
        button.setAttribute("aria-current", false);
        button.classList.remove(openClass);
      });
    };

    const handleClickOutside = e => {
      if (e.target.closest(".js-sticky-toolbar")) {
        return;
      } else {
        closeAll();
        panelContainer.classList.remove(openClass);
      }
    };

    const handleTabChange = button => {
      const contentType = button.getAttribute("data-tab");
      const content = el.querySelector(`[data-content=${contentType}]`);

      if (content.classList.contains(openClass)) {
        //remove panel
        content.classList.remove(openClass);
        button.setAttribute("aria-current", false);
        button.classList.remove(openClass);
        panelContainer.classList.remove(openClass);
        document.removeEventListener("click", handleClickOutside);
      } else {
        //reset any open panels
        closeAll();
        //set new open panel
        content.classList.add(openClass);
        button.setAttribute("aria-current", true);
        button.classList.add(openClass);
        panelContainer.classList.add(openClass);
        //set focus after css height animation
        document.addEventListener("click", handleClickOutside);
        setTimeout(() => {
          content.closest(".js-sticky-toolbar-tabpanel").focus();
        }, 251);
      }
    };

    buttons.length &&
      [...buttons].forEach(button =>
        button.addEventListener("click", e => {
          e.preventDefault();
          handleTabChange(button);
        })
      );
  });
};
