import throttle from "lodash.throttle";

// This code looks for
// 1. A hash in the url and will scroll to a matching id on the page if found
// 2. Anchor links on the page containing "#" and will scroll the page to a matching target if they exist.

export default function inPageNavigation(links = []) {
  const targets = [];
  const animationTime = 750;
  let isScrolling = false;
  let activeLinkIndex = 0;

  if (links && links.length > 0) {
    [...links].forEach(initLinks);
  }

  function initLinks(link, index) {
    const href = link.getAttribute("href");
    const hash = `#${href.split("#")[1]}`;

    if (hash.length <= 1) {
      return;
    }

    let target = null;

    try {
      target = document.querySelector(hash);
    } catch (e) {
      console.warn(`${hash} is not a valid target`);
    }

    if (target) {
      target.dataset.index = index;
      targets.push(target);

      link.addEventListener("click", (e) => {
        e.preventDefault();

        // manually move the aria current
        // scroll event might do this as well, but better to make sure
        setCurrentLink(link);

        scrollPageTo(target);
      });
    }

    handleScroll(targets);
    window.addEventListener(
      "scroll",
      throttle(() => {
        handleScroll(targets);
      }, animationTime)
    );
  }

  function handleHashUrl(hashOffset = 0) {
    const urlHash = window.location.hash;

    if (!urlHash || urlHash.length <= 1) {
      return;
    }

    const target = document.querySelector(urlHash);

    if (target) {
      setTimeout(() => scrollPageTo(target, hashOffset), 750);
    }
  }

  function scrollPageTo(target, scrollOffset = 0) {
    // https://stackoverflow.com/questions/52292603/is-there-a-callback-for-window-scrollto/55686711#55686711
    isScrolling = true;

    const fixedOffset = (getOffset(target).top - scrollOffset).toFixed();
    const onScroll = function () {
      if (window.pageYOffset.toFixed() === fixedOffset) {
        window.removeEventListener("scroll", onScroll);
        isScrolling = false;
        target.focus();
      }
    };

    window.addEventListener("scroll", onScroll);
    onScroll();
    window.scrollTo({
      top: fixedOffset,
      behavior: "smooth",
    });
  }

  // on page scroll, move the aria-current attribute to the appropriate link
  function handleScroll(targets) {
    if (isScrolling) {
      return;
    }

    const target = getCurrentTarget(targets);

    if (target && target.dataset.index !== activeLinkIndex) {
      activeLinkIndex = parseInt(target.dataset.index);
      setCurrentLink(links[activeLinkIndex]);
    }
  }

  // Takes a Node List of anchor tags on the page and a callback function
  function getCurrentTarget(targets) {
    return [...targets].find((target) => {
      const rect = target.getBoundingClientRect();
      return (
        rect.top < window.innerHeight / 2 + 100 &&
        rect.bottom > window.innerHeight / 2 - 100
      );
    });
  }

  function getOffset(target) {
    const rect = target.getBoundingClientRect();
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  function setCurrentLink(link) {
    [...links].forEach((link) => {
      link.removeAttribute("aria-current");
    });

    link.setAttribute("aria-current", "location");
  }

  return {
    scrollPageTo,
    getOffset,
    handleHashUrl,
    getCurrentTarget,
  };
}
