import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { array, number, object } from "prop-types";
import cx from "classnames";
import throttle from "lodash.throttle";

import inPageNavigation from "../../common/inPageNavigation.js";

const propTypes = {
  links: array.isRequired,
  jumpOffset: number.isRequired,
  dictionary: object,
};

const JumpNavigation = (props) => {
  const { links, jumpOffset, dictionary } = props;

  const itemsRef = useRef();

  const navHelper = useMemo(() => inPageNavigation(), []);

  const targets = useMemo(() => {
    return links.map((link) => document.querySelector(link.href));
  }, [links]);

  const [wideView, setWideView] = useState(true);
  const [focusIndex, setFocusIndex] = useState(-1);
  const [activeLinkIndex, setActiveLinkIndex] = useState(-1);

  const [isScrolling, setIsScrolling] = useState(false);

  const scrollNavToSection = useCallback((section) => {
    const el = itemsRef.current.children[section];

    if (el) {
      const center = el.offsetLeft + el.offsetWidth / 2;

      const scrollLeft = center - itemsRef.current.offsetWidth / 2;

      itemsRef.current.scroll({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  }, []);

  const handleLinkClick = (e, index) => {
    e.preventDefault();
    setIsScrolling(true);
    scrollNavToSection(index);
    setFocusIndex(index);
    setActiveLinkIndex(index);
    navHelper.scrollPageTo(targets[index], jumpOffset);

    setTimeout(() => {
      setIsScrolling(false);
    }, 1000);
  };

  const handleMenuItemKeyboard = (e) => {
    if (wideView) {
      return;
    }

    switch (e.key) {
      case "Up":
      case "ArrowUp":
        e.preventDefault();
        if (focusIndex === 0) {
          setFocusIndex(links.length - 1);
        } else {
          setFocusIndex(focusIndex - 1);
        }
        break;
      case "Down":
      case "ArrowDown":
        e.preventDefault();
        if (focusIndex === links.length - 1) {
          setFocusIndex(0);
        } else {
          setFocusIndex(focusIndex + 1);
        }
        break;

      default:
    }
  };

  useEffect(() => {
    const hashTag = window.location.hash || "";

    if (hashTag.startsWith("#nav-")) {
      const newIndex = parseInt(hashTag.slice(-1)) || -1;

      navHelper.handleHashUrl(jumpOffset);

      setTimeout(() => {
        setActiveLinkIndex(newIndex);
      }, 1500);
    }
  }, [navHelper, jumpOffset]);

  useEffect(() => {
    const handleResize = () => {
      const newWideView =
        itemsRef.current.offsetWidth >= itemsRef.current.scrollWidth;

      if (newWideView !== wideView) {
        setWideView(newWideView);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [wideView]);

  useEffect(() => {
    const throttleHandleScroll = throttle((event) => {
      if (isScrolling) {
        return;
      }

      const index = [...targets].reduce((index, target) => {
        const rect = target.getBoundingClientRect();
        return rect.top <= jumpOffset ? index + 1 : Math.max(index, 0);
      }, -1);

      if (index !== activeLinkIndex) {
        event.preventDefault();
        setActiveLinkIndex(index);
        scrollNavToSection(index);
      }
    }, 100);

    window.addEventListener("scroll", throttleHandleScroll);

    return () => {
      window.removeEventListener("scroll", throttleHandleScroll);
    };
  }, [activeLinkIndex, isScrolling, jumpOffset, targets, scrollNavToSection]);

  return (
    <nav
      className={cx("ssa__jump-navigation", {
        "ssa__jump-navigation--is-overflowed": !wideView,
      })}
    >
      <ul className="ssa__jump-navigation__items" tabIndex="-1" ref={itemsRef}>
        {links.map((link) => (
          <li
            id={`sf-tab-${link.index}`}
            key={link.index}
            className={cx("ssa__jump-navigation__item", {
              "ssa__jump-navigation__item--active": link.index === activeLinkIndex,
            })}
          >
            <a
              aria-current={link.index === activeLinkIndex ? "location" : null}
              className="ssa__jump-navigation__link"
              ref={(el) => focusIndex === link.index}
              href={link.href}
              onClick={(e) => handleLinkClick(e, link.index)}
              onKeyDown={(e) => handleMenuItemKeyboard(e, link.index)}
            >
              {link.label}
            </a>
          </li>
        ))}
      </ul>
      <a href="#top" className="ssa__jump-navigation__to-top" onClick={(e) => {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }}>
        <svg role="presentation" height="5" viewBox="0 0 10 5" width="10" xmlns="http://www.w3.org/2000/svg"><path d="m244 18h10l-5 5z" fill="currentColor" fillRule="evenodd" transform="matrix(-1 0 0 -1 254 23)"/></svg>
        {dictionary.backToTop}
      </a>
    </nav>
  );
};
JumpNavigation.propTypes = propTypes;
export default JumpNavigation;
