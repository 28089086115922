import React from "react";
import ReactDom from "react-dom";
import AnchorLinkNav from "./AnchorLinkNav.jsx";

export default function renderAnchorLinkNav() {
  document.querySelectorAll(".js-ssa__anchor-link-nav-mount").forEach((mount, i) => {
    const dictionary = JSON.parse(mount.dataset.dictionary);

    ReactDom.render(<AnchorLinkNav dictionary={dictionary} />, mount);
  });
}
