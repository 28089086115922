// Accordion Code created by Jonathan Dallas - 2019
// Version 1.1.1

// Expected markup.
// The link and content need to be nested within the js-accordion element, but can be placed anywhere in the code.
//
// <div class="js-accordion is-open">
//   <div>
//     <button class="js-accordion-link" type="button" aria-expanded="true"><span>View all</span></button>
//   </div>
//   <div class="js-accordion-content">
//       Content to show and hide
//   </div>
// </div>

export function initAccordions() {
  Array.from(document.querySelectorAll(".js-ssa__accordion")).forEach(
    (el, index) => {
      initAccordion(el, index);
    },
  );
}

export default function initAccordion(el, id) {
  const link = el.querySelector(".js-ssa__accordion-link");
  const content = el.querySelector(".js-ssa__accordion-content");

  if (!link || !content) {
    return false;
  }

  const freezeBody = () => {
    document.body.style.overflowY = "hidden";
  };

  const unfreezeBody = () => {
    document.body.style.overflowY = "visible";
  };

  const contentWrapper = wrap(content, document.createElement("div"));

  contentWrapper.classList.add("ssa__accordion-wrapper");

  // check if we're in the experience editor to force the content open and prevent the binding event for the link.
  const editorView = document.querySelector(".is-page-editor");

  let expanded = false;
  let contentBuffer = setTimeout(() => {}, 1);

  const animationSpeed = 500;

  if (el.classList.contains("is-open") || editorView) {
    expanded = true;
    toggleContent(expanded);
  } else {
    setHeight(contentWrapper, "0px");
  }

  link.setAttribute("aria-expanded", expanded);
  link.setAttribute("id", `ssa__accordion-link-${id}`);
  link.setAttribute("aria-controls", `ssa__accordion-content-${id}`);

  contentWrapper.setAttribute("id", `ssa__accordion-content-${id}`);
  contentWrapper.setAttribute("aria-labelledby", `ssa__accordion-link-${id}`);

  if (editorView === null) {
    link.addEventListener("click", e => {
      e.preventDefault();
      toggleContent(!expanded);
    });

    content.addEventListener("click", e => {
      if (content.closest(".js-ssa__accordion[data-close-on-click]")) {
        toggleContent(!expanded);
      }
    });
  }

  function toggleContent(openState) {
    if (openState === expanded) {
      return;
    }

    const triggerModal = el.dataset.triggerModal;

    const height = content.scrollHeight;
    expanded = openState;

    link.setAttribute("aria-expanded", openState);

    clearTimeout(contentBuffer);

    if (openState) {
      el.classList.add("is-open");
      contentWrapper.style.setProperty("--visibility", "visible");
      setHeight(contentWrapper, `${height}px`);
      contentBuffer = setTimeout(() => {
        setHeight(contentWrapper, "auto");
        contentWrapper.style.setProperty("--overflow", "visible");
      }, animationSpeed + 20);

      if (triggerModal === "true") {
        freezeBody();
      }
    } else {
      el.classList.remove("is-open");
      setHeight(contentWrapper, `${height}px`);

      setTimeout(() => {
        setHeight(contentWrapper, "0");
      }, 20);

      setTimeout(() => {
        contentWrapper.style.setProperty("--visibility", "hidden");
      }, animationSpeed + 20);

      if (triggerModal === "true") {
        unfreezeBody();
      }
    }
  }

  function setHeight(el, height) {
    el.style.setProperty("--height", height);
    el.style.setProperty("--animation-speed", `${animationSpeed}ms`);
    el.style.setProperty("--overflow", "hidden");
  }

  function wrap(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
    return wrapper;
  }

  return {
    el,
    toggleContent,
  };
}
