import Swiper from "swiper"; // use import for storybook
// const Swiper = require("swiper");

import { relatedInsight } from "./components/RelatedInsight/related-insight.js";
import renderAnchorLinkNav from "./components/AnchorLinkNav/renderAnchorLinkNav.jsx";
import { stickyToolbar } from "./components/StickyToolbar/sticky-toolbar.js";
// import renderJumpNavigation from "./components/JumpNavigation/renderJumpNavigation.jsx";

document.addEventListener("DOMContentLoaded", () => {
  relatedInsight();
  stickyToolbar();
  renderAnchorLinkNav();
  // renderJumpNavigation();

  const body = document.body;
  let winsize;
  let winOrientation;
  let currentBreakpoint;
  let currentVerticalBreakpoint;

  // Classes
  class TiltFx {
    constructor(el, options) {
      this.DOM = { el: el };
      this.options = {
        valuesFromTo: [-50, 50],
        lerpFactorOuter: 0.25,
        lerpFactor: (pos) => 0.05 * Math.pow(2, pos),
      };
      Object.assign(this.options, options);
      this.DOM.moving = [...this.DOM.el.children];
      this.movingTotal = this.DOM.moving.length;
      this.mousePos = { x: winsize.width / 2, y: winsize.height / 2 };
      this.translations = [...new Array(this.movingTotal)].map(() => ({
        x: 0,
        y: 0,
      }));
      this.initEvents();
    }
    initEvents() {
      window.addEventListener("mousemove", (ev) => {
        this.mousePos = getMousePos(ev);
      });
    }
    render() {
      for (let i = 0; i <= this.movingTotal - 1; ++i) {
        let lerpFactor =
          i < this.movingTotal - 1
            ? this.options.lerpFactor(i)
            : this.options.lerpFactorOuter;
        this.translations[i].x = lerp(
          this.translations[i].x,
          lineEq(
            this.options.valuesFromTo[1],
            this.options.valuesFromTo[0],
            winsize.width,
            0,
            this.mousePos.x,
          ),
          lerpFactor,
        );
        this.translations[i].y = lerp(
          this.translations[i].y,
          lineEq(
            this.options.valuesFromTo[1],
            this.options.valuesFromTo[0],
            winsize.height,
            0,
            this.mousePos.y,
          ),
          lerpFactor,
        );
        this.DOM.moving[i].style.transform =
          `translateX(${this.translations[i].x}px) translateY(${this.translations[i].y}px)`;
      }
      this.requestId = requestAnimationFrame(() => this.render());
    }
    start() {
      if (!this.requestId) {
        this.requestId = window.requestAnimationFrame(() => this.render());
      }
    }
    stop() {
      if (this.requestId) {
        window.cancelAnimationFrame(this.requestId);
        this.requestId = undefined;

        for (let i = 0; i <= this.movingTotal - 1; ++i) {
          this.translations[i].x = 0;
          this.translations[i].y = 0;
          this.DOM.moving[i].style.transform =
            "translateX(0px) translateY(0px)";
        }
      }
    }
  }

  class Figure {
    constructor(el) {
      this.DOM = { el: el };
      this.DOM.img = this.DOM.el.querySelector(".sshr__hero-slide-bg-image");
      this.DOM.slideEl = this.DOM.img;
      // We will add a tilt effect for the main figure. For this we will create clones of the main image that will move together
      // with the main image when the user moves the mouse.
      if (
        this.DOM.el.classList.contains("sshr__hero-slide-bg-image-container")
      ) {
        this.isMain = true;
        // Number of total images (main image + clones).
        this.totalTiltImgs = 2;
        this.DOM.inner = document.createElement("div");
        this.DOM.slideEl = this.DOM.inner;
        this.DOM.inner.className = "sshr__hero-slide-motion-effect-wrapper";
        this.DOM.el.appendChild(this.DOM.inner);
        this.DOM.inner.appendChild(this.DOM.img);
        for (let i = 0; i <= this.totalTiltImgs; ++i) {
          this.DOM.inner.appendChild(this.DOM.img.cloneNode(true));
        }
        // Initialize the tilt effect.
        this.tilt = new TiltFx(this.DOM.inner, {
          valuesFromTo: [10, -10],
          lerpFactorOuter: 0.1,
          lerpFactor: (pos) => 0.04 * pos + 0.04,
        });
      }
    }
  }

  // Helper functions
  const lineEq = (y2, y1, x2, x1, currentVal) => {
    let m = (y2 - y1) / (x2 - x1);
    let b = y1 - m * x1;
    return m * currentVal + b;
  };
  const lerp = (a, b, n) => (1 - n) * a + n * b;

  const getMousePos = (e) => {
    let posx = 0;
    let posy = 0;
    if (!e) e = window.event;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + body.scrollLeft + document.documentElement.scrollLeft;
      posy = e.clientY + body.scrollTop + document.documentElement.scrollTop;
    }
    return { x: posx, y: posy };
  };

  const preloadImage = (imageURL) => {
    const img = new Image();
    img.src = imageURL;
  };

  const calcWinsize = () =>
    (winsize = {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });

  const breakpoints = {
    xsmall: 360,
    small: 480,
    medium: 768,
    large: 1000,
    xlarge: 1440,
  };

  const calcBreakpoint = (size) => {
    if (size >= breakpoints.small && size < breakpoints.medium) {
      currentBreakpoint = "small";
    } else if (size >= breakpoints.medium && size < breakpoints.large) {
      currentBreakpoint = "medium";
    } else if (size >= breakpoints.large && size < breakpoints.xlarge) {
      currentBreakpoint = "large";
    } else if (size >= breakpoints.xlarge) {
      currentBreakpoint = "xlarge";
    } else {
      currentBreakpoint = "xsmall";
    }
  };

  const calculateOrientation = () => {
    if (window.matchMedia("(orientation: landscape)").matches) {
      winOrientation = "landscape";
    }
    if (window.matchMedia("(orientation: portrait)").matches) {
      winOrientation = "portrait";
    }
  };

  const verticalBreakpoints = {
    xsmall: 360,
    small: 605,
    medium: 768,
    large: 1024,
    xlarge: 1280,
  };

  const calcVerticalBreakpoint = (size) => {
    if (
      size >= verticalBreakpoints.small &&
      size < verticalBreakpoints.medium
    ) {
      currentVerticalBreakpoint = "small";
    } else if (
      size >= verticalBreakpoints.medium &&
      size < verticalBreakpoints.large
    ) {
      currentVerticalBreakpoint = "medium";
    } else if (
      size >= verticalBreakpoints.large &&
      size < verticalBreakpoints.xlarge
    ) {
      currentVerticalBreakpoint = "large";
    } else if (size >= verticalBreakpoints.xlarge) {
      currentVerticalBreakpoint = "xlarge";
    } else {
      currentVerticalBreakpoint = "xsmall";
    }
  };

  // Initialization:
  calcWinsize();
  calcBreakpoint(winsize.width);
  calcVerticalBreakpoint(winsize.height);
  calculateOrientation();

  // Recalculate window sizes on resize.
  window.addEventListener("resize", () => {
    calcWinsize();
    calcBreakpoint(winsize.width);
    calcVerticalBreakpoint(winsize.height);
    calculateOrientation();
  });

  // Initialize Heros
  document.querySelectorAll(".sshr__hero__wrapper").forEach((heroInstance) => {
    const heroContainer = heroInstance.closest(".sshr__hero");
    const effect = heroContainer.dataset.effect;
    const substractElClass =
      heroContainer && heroContainer.dataset.substractHeightFrom;
    const substractEl =
      substractElClass && document.getElementsByClassName(substractElClass)[0];
    const navItems =
      heroContainer && JSON.parse(heroContainer?.dataset?.navItems);
    const playPauseButton =
      heroContainer && heroInstance.querySelector(".js-play-pause-button");
    const heroSlideLength =
      heroInstance.getElementsByClassName("swiper-slide").length;

    // set hero size
    const calculateHeroSize = () => {
      let minHeroContainerHeight = 0;
      let maxClientHeight = 800;
      [
        ...heroContainer.getElementsByClassName("sshr__hero-slide__content"),
      ].forEach((element) => {
        minHeroContainerHeight = Math.max(
          Math.min(maxClientHeight, element.clientHeight),
          minHeroContainerHeight,
        );
      });

      let heroContainerHeight = 0;
      if (
        winOrientation === "landscape" &&
        currentVerticalBreakpoint === "xsmall"
      ) {
        heroContainerHeight = 470;
      } else if (
        winOrientation === "portrait" &&
        currentVerticalBreakpoint === "xsmall"
      ) {
        heroContainerHeight = 520;
      } else {
        heroContainerHeight =
          Math.min(winsize.height, maxClientHeight) -
          (substractEl ? substractEl.clientHeight : 0);
      }

      heroContainer.style.height = `${Math.max(
        minHeroContainerHeight,
        heroContainerHeight,
      )}px`;
    };

    calculateHeroSize();
    window.addEventListener("resize", calculateHeroSize);

    const heroSlider = new Swiper(heroInstance, {
      autoplay:
        heroSlideLength > 1
          ? {
              delay: 10000,
              disableOnInteraction: false,
            }
          : undefined,
      direction: "horizontal",
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      slidesPerView: 1,
      loop: heroSlideLength > 1 ? true : false,
      preventInteractionOnTransition: true,
      touchReleaseOnEdges: true,
      mousewheel: false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: (index, className) => {
          return `<a class=${className} href="${
            navItems[index]?.link || ""
          }"><span id="progressBar" class="bar-progress"></span><span class="${className}-nav-item-text">${
            navItems[index]?.text || ""
          }</span></a>`;
        },
      },
      on: {
        init: function () {
          // Initialize zoom effect if there's only one slide
          if (effect === "zoom" && heroSlideLength === 1) {
            const currentSlide = this.slides[this.activeIndex];
            const backgroundImageContainer = currentSlide.querySelector(
              ".sshr__hero-slide-bg-image-container",
            );
            backgroundImageContainer?.classList.add(
              "sshr__hero-slide-bg-image-container--effect-zoom",
            );
            return;
          }

          // Initialize motion effect
          if (effect === "motion") {
            for (const slide of Array.from(this.slides)) {
              const figure = slide.querySelector(
                ".sshr__hero-slide-bg-image-container",
              );
              if (figure) {
                figure.classList.add(
                  "sshr__hero-slide-bg-image-container--effect-motion",
                );
                const motionImage = new Figure(figure);
                motionImage.tilt.start();
              }
            }
          }
        },
        paginationUpdate: function () {
          const { autoplay } = this;

          for (const bullet of Array.from(this.pagination.bullets)) {
            const isActive = bullet.classList.contains(
              "swiper-pagination-bullet-active",
            );
            if (autoplay.running && isActive) {
              // Start bullet progress animation
              bullet.classList.add(
                "swiper-pagination-bullet-active--state-playing",
              );
            } else {
              // Stop bullet progress animation
              bullet.classList.remove(
                "swiper-pagination-bullet-active--state-playing",
              );
            }
          }
        },
        slideChangeTransitionStart: function () {
          if (effect !== "zoom") return;

          const currentSlide = this.slides[this.activeIndex];
          const backgroundImageContainer = currentSlide.querySelector(
            ".sshr__hero-slide-bg-image-container",
          );
          backgroundImageContainer?.classList.add(
            "sshr__hero-slide-bg-image-container--effect-zoom",
          );
        },
        slideChangeTransitionEnd: function () {
          if (effect !== "zoom") return;

          // Due to weirdness in the Swiper library, we need to clear all non-active
          // slides of the effects, not just the previous one
          for (const slide of Array.from(this.slides)) {
            if (!slide.classList.contains("swiper-slide-active")) {
              const backgroundImageContainer = slide.querySelector(
                ".sshr__hero-slide-bg-image-container",
              );
              backgroundImageContainer?.classList.remove(
                "sshr__hero-slide-bg-image-container--effect-zoom",
              );
            }
          }
        },
        autoplayStart: function () {
          // Start bullet progress animation
          for (const bullet of Array.from(this.pagination.bullets)) {
            const isActive = bullet.classList.contains(
              "swiper-pagination-bullet-active",
            );
            if (isActive) {
              // The setTimeout makes sure the .progress-bar starts with a
              // width of 0 on page load, which is needed to animate properly
              setTimeout(
                () =>
                  bullet.classList.add(
                    "swiper-pagination-bullet-active--state-playing",
                  ),
                0,
              );
              break;
            }
          }
        },
        autoplayStop: function () {
          // Stop bullet progress animation
          for (const bullet of Array.from(this.pagination.bullets)) {
            const isActive = bullet.classList.contains(
              "swiper-pagination-bullet-active",
            );
            if (isActive) {
              bullet.classList.remove(
                "swiper-pagination-bullet-active--state-playing",
              );
              break;
            }
          }
        },
      },
    });

    if (playPauseButton) {
      playPauseButton.addEventListener("click", (e) => {
        e.preventDefault();
        const { running: isRunning, start, stop } = heroSlider.autoplay;

        const pauseIcon = document.getElementById("pauseBtn");
        const playIcon = document.getElementById("playBtn");

        if (isRunning) {
          pauseIcon.classList.add("hide-btn");
          playIcon.classList.remove("hide-btn");
          stop();
        } else {
          pauseIcon.classList.remove("hide-btn");
          playIcon.classList.add("hide-btn");
          start();
        }
      });
    }
  });

  // Inititalize Featured Articles Sliders
  [
    ...document.querySelectorAll(".sshr__featured-articles__slider-container"),
  ].forEach((slider) => {
    const articlesSlider = new Swiper(slider, {
      breakpoints: {
        400: {
          spaceBetween: 35,
        },
      },
      centeredSlides: true,
      direction: "horizontal",
      loop: true,
      slideClass: "sshr__article",
      slidesPerView: "auto",
      spaceBetween: 25,
      wrapperClass: "sshr__featured-articles__slider-wrapper",
    });
  });

  // Toggle class behavior
  [...document.querySelectorAll("[data-toggle]")].forEach((toggler) => {
    const toggleClass = toggler.dataset.toggleClass;
    const toggleTargetClass = toggler.dataset.toggleTarget;
    const toggleTarget = toggler.closest(`.${toggleTargetClass}`);
    const toggleMaxWidth = toggler.dataset.toggleMaxWidth;

    toggler.addEventListener("click", (e) => {
      e.preventDefault();

      if (
        !toggleMaxWidth ||
        !toggleMaxWidth.length ||
        toggleMaxWidth >= window.innerWidth
      ) {
        toggleTarget && toggleTarget.classList.toggle(toggleClass);
      }
    });
  });
});
